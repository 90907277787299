<template>
  <header class="fix">
    <div class="h_left">
      <!-- 漢堡選單按鈕 -->
      <div class="hamburger">
        <div
          id="hamburger"
          @click="ifMobileMenuOpen = true">
            <div></div>
        </div>
      </div>
      <!-- Logo -->
      <router-link to="/" class="logo">
        <img src="@/assets/img/logo.svg" alt="">
      </router-link>
    </div>

    <!-- 選單 -->
    <nav
      class="nav"
      :class="{active: ifMobileMenuOpen}">
        <img
          src="@/assets/img/icon/icon_X-w.svg"
          alt=""
          @click="ifMobileMenuOpen = false"
          class="close">
        <div
          v-for="(item, key) in navData"
          :key="key"
          class="item">
            <!-- 主選單項目 -->
            <!-- 有子選單時加上.hassub並不要連結 -->
            <router-link
              :to="$route"
              v-if="item.sub"
              @click.native="item.openSub = !item.openSub"
              class="main_link hassub">
                {{item.main.text}}
            </router-link>

            <!-- 沒有子選單時單純連結 -->
            <router-link
              v-else
              :to="(item.main.link) ? item.main.link : ''"
              class="main_link">
                {{item.main.text}}
            </router-link>

            <!-- 子選單 -->
            <ul
              v-if="item.sub"
              class="sublist"
              :class="{active: item.openSub}">
                <li
                  v-for="(subitem, subkey) in item.sub"
                  :key="subkey">
                    <!-- 子選單項目 -->
                    <!-- 點擊要執行某個function -->
                    <a
                      v-if="subitem.specialFunc"
                      @click="subitem.specialFunc">
                        {{subitem.text}}
                    </a>
                    <!-- 點擊連結某頁 -->
                    <router-link
                      v-else
                      :to="(subitem.link) ? subitem.link : ''">
                        {{subitem.text}}
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>

    <!-- 功能 -->
    <div class="features">
      <!-- 搜尋 -->
      <router-link
        :to="{ name: 'Search' }"
        class="search">
          <img src="@/assets/img/icon/icon_search.svg" alt="" style="">
      </router-link>

      <!-- 購物車 -->
      <router-link
        :to="{ name: 'CartInfo' }"
        v-if="isLogin"
        class="cart">
          <span class="message" id="cartNotify" v-if="cart > 0">{{cart}}</span>
          <img src="@/assets/img/icon/icon_car.svg" alt="">
      </router-link>

      <!-- user -->
      <a
        id="header_member_btn"
        class="member"
        v-if="ifToken"
        @click="toggleMemberNavShow();">
          <span
            v-if="noticeUnread"
            class="message"
            id="cartNotify">
              {{noticeUnread}}
          </span>
          <p>{{user.name || '使用者'}} 您好</p>
          <img src="@/assets/img/icon/icon_member.svg" alt="" style="width:21px;">
      </a>

      <!-- 登入 -->
      <div
        @click="login"
        v-else
        class="member">
          <p>登入</p>
          <img src="@/assets/img/icon/icon_member.svg" alt="" style="width:21px;">
      </div>
    </div>

    <!-- 黑色遮罩 -->
    <div
      id="nav_mobile_cover"
      @click="ifMobileMenuOpen = false"
      :class="{active: ifMobileMenuOpen}">
    </div>

    <!-- 會貼在header下面的東西 -->
    <div class="subbox">
      <!-- 會員子選單 -->
      <div
        id="member_features"
        class="member_features"
        :class="{active : memberNavShow}"
        v-if="ifToken">
          <ul class="txt-blue">
            <li>
              <router-link
                :to="{ name: 'MemberData' }"
                class="small">
                  會員中心
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'MemberOrder' }"
                class="small">
                  訂單查詢
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'MemberTrack' }"
                id="trackList"
                class="small"
                data-message="0">
                  追蹤清單
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'MemberDiscount' }"
                class="small"
                data-message="0">
                  我的優惠
              </router-link>
            </li>
            <li>
              <router-link
                :to="{ name: 'MemberNotice' }"
                class="small"
                :class="{active: noticeUnread}"
                :data-message="noticeUnread">
                  我的通知
              </router-link>
            </li>
            <li>
              <button
                class="small"
                @click="logout">
                  登出
              </button>
            </li>
          </ul>
      </div>

      <!-- 立即預定lightbox按鈕, 在預購&購買&結帳頁面不會出現 -->
      <!-- <span
        class="reserve_now"
        href="https://docs.google.com/forms/d/e/1FAIpQLSf9hObXXivKkPlkGxOZ6oHdAzBeYn1CxknzP7pbL9KurNBBDA/viewform"
        target="_blank">
          <img src="@/assets/img/icon/icon_wiho.svg" alt="">
          <p class="txt-white">立即預約</p>
      </span> -->
    </div>
  </header>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { checkStorage } from '@/utils/timerStorage';
import requestApi from '@/lib/http/index';

export default {
  name: 'Header',
  data() {
    return {
      navData: [
        {
          main: {
            text: '最新活動',
            // link: '/',
          },
          openSub: false,
          sub: [
            {
              text: '活動資訊',
              link: {
                name: 'Events',
                query: { category: '', page: '1' },
              },
            },
            {
              text: '最新消息',
              link: {
                name: 'News',
                query: { category: '', page: '1' },
              },
            },
          ],
        },
        // {
        //   main: {
        //     text: 'WiFi分享器',
        //     link: {
        //       name: 'Product',
        //       params: { category: 1 },
        //       query: { productCategory: 1, sort: 0, code: this.$route.query.code },
        //     },
        //   },
        // },
        {
          main: {
            text: 'SIM卡',
            link: {
              name: 'Product',
              params: { category: 1 },
              query: { productCategory: 2, sort: 0, code: this.$route.query.code },
            },
          },
        },
        // {
        //   main: {
        //     text: 'eSIM',
        //     link: {
        //       name: 'Product',
        //       params: { category: 1 },
        //       query: { productCategory: 3, sort: 0, code: this.$route.query.code },
        //     },
        //   },
        // },
        {
          main: {
            text: '深紫外線UVC LED',
            // link: '/',
          },
          openSub: false,
          sub: [],
        },
        {
          main: {
            text: '週邊3C',
            link: {
              name: 'Product',
              params: { category: 1 },
              query: {
                unspecifiedTime: 0, productCategory: '週邊3C', sort: 1 || this.$route.query.code,
              },
            },
          },
        },
        {
          main: {
            text: '機場櫃台',
            link: '/qwe',
          },
          openSub: false,
          sub: [
            {
              text: '桃園國際機場',
              link: '/Airport/1',
            },
            {
              text: '台北松山機場',
              link: '/Airport/2',
            },
            {
              text: '高雄小港機場',
              link: '/Airport/3',
            },
          ],
        },
        {
          main: {
            text: '企業專區',
            // link: '/',
          },
          openSub: false,
          sub: [
            {
              text: '企業服務',
              link: '/CompanyArea',
            },
            {
              text: '洽詢商務合作',
              specialFunc: () => {
                this.companyLightboxStatusChange(true);
              },
            },
          ],
        },
        {
          main: {
            text: '客服中心',
            // link: '/',
          },
          openSub: false,
          sub: [
            {
              text: '常見問題',
              link: '/Faq/1',
            },
          ],
        },
        {
          main: {
            text: '生活講堂',
            // link: '/',
          },
          openSub: false,
          sub: [
            {
              text: 'Wi-Ho! Blog',
              link: {
                name: 'Blog',
                query: { category: '', page: '1' },
              },
            },
            {
              text: '異業合作',
              specialFunc: () => {
                this.coopLightboxStatusChange(true);
              },
            },
          ],
        },
      ],
      memberNavShow: false,
      ifToken: false,
      ifMobileMenuOpen: false,
      noticeUnread: 0,
    };
  },
  computed: {
    ...mapState([
      'lightboxStatus',
      'cart',
      'isLogin',
      'user',
    ]),
  },
  methods: {
    ...mapActions({
      actionLogout: 'logout',
    }),
    ...mapMutations([
      'coopLightboxStatusChange',
      'companyLightboxStatusChange',
      'setCart',
    ]),
    toggleMemberNavShow() {
      this.memberNavShow = !this.memberNavShow;
    },
    async getProductCatagory() {
      const result = await requestApi('req.getProductCategories', { parent: '旅遊周邊' });
      const arr = [];
      if (result.status) {
        result.data.forEach((element) => {
          if (element.name !== 'WIFI分享器' && element.name !== 'SIM卡' && element.name !== 'eSIM') {
            arr.push({
              text: element.name,
              link: {
                name: 'Product',
                params: { category: 2 },
                query: {
                  productCategory: element.name,
                  // sort: 1,
                  sort: 0,
                  code: null || this.$route.query.code,
                },
              },
            });
          }
        });
        this.navData.forEach((item, i) => {
          if (item.main.text === '深紫外線UVC LED') {
            this.navData[i].sub = arr;
          }
        });
      }
    },
    logout() {
      this.actionLogout();
      this.ifToken = checkStorage('token');
      this.$router.go(0);
      // this.$router.push({ name: 'Index' });
    },
    login() {
      this.$router.push(
        {
          name: 'Login',
          query: { pathname: window.location.pathname, search: window.location.search },
        },
      );
    },
    async getCart() {
      if (this.isLogin) {
        // eslint-disable-next-line
        const member_id = this.user.id;
        const result = await requestApi('cart.index', { member_id });
        if (result.status) {
          if (result.data.length > 0) {
            this.setCart(result.data.length);
          }
        }
      }
    },
    async getUnreadMsgCount() {
      const token = localStorage.getItem('ust');
      if (token) {
        const { data } = await requestApi('member.getUnreadMsgCount');
        this.noticeUnread = data;
      }
    },
  },
  created() {
    this.getProductCatagory();
    this.getCart();
  },
  mounted() {
    this.ifToken = checkStorage('token');
  },
  updated() {
    this.getUnreadMsgCount();
  },
};
</script>
