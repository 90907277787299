<template>

  <div>
    <div
      id="to_top"
      class="fixed_circle active"
      :class="{
        unactive: $route && $route.name && ($route.name.indexOf('Cart') >= 0 ||
        $route.name.indexOf('Login') >= 0 ||
        $route.name.indexOf('Register') >= 0 ||
        $route.name.indexOf('ForgetPassword') >= 0 ||
        $route.name.indexOf('Member') >= 0)
      }">
        <img
          src="@/assets/img/icon/top_wave.svg"
          alt=""
          @click="scrollToTop">
    </div>
    <!-- <div id="fb-root" class="fixed_circle active"></div> -->

    <footer>
      <div class="inner w1300">
        <router-link to="/" class="logo">
          <img src="@/assets/img/logo.png" alt="">
        </router-link>
        <nav class="nav">
          <div class="footer_nav_item">
            <h6 class="sub_title txt-bold">Wi-Ho!產品</h6>
            <!-- <router-link
              :to="{
                name: 'Product',
                params: { category: 1 },
                query: { productCategory: 1, sort: 1, code: null || this.$route.query.code },
              }" class="sub_link">
                <p>WiFi分享器</p>
            </router-link> -->
            <router-link
              :to="{
                name: 'Product',
                params: { category: 1 },
                query: { productCategory: 2, sort: 1, code: null || this.$route.query.code },
              }" class="sub_link">
                <p>SIM卡</p>
            </router-link>
            <!-- <router-link
              :to="{
                name: 'Product',
                params: { category: 1 },
                query: { productCategory: 3, sort: 1, code: null || this.$route.query.code },
              }" class="sub_link">
                <p>eSIM</p>
            </router-link> -->
            <router-link
              :to="{
                name: 'Product',
                params: { category: 2 },
                query: { productCategory: 0, sort: 1, code: null || this.$route.query.code },
              }" class="sub_link">
                <p>深紫外線UVC LED</p>
            </router-link>
            <router-link
              :to="{
                name: 'Product',
                params: { category: 1 },
                query: {
                  unspecifiedTime: 0, productCategory: '週邊3C', sort: 1 || this.$route.query.code,
                },
              }" class="sub_link">
                <p>週邊3C</p>
            </router-link>
          </div>
          <div class="footer_nav_item">
            <h6 class="sub_title txt-bold">關於Wi-Ho!</h6>
            <router-link
              to="/About"
              class="sub_link">
                <p>公司介紹</p>
            </router-link>
            <router-link
              to="/About?history=1"
              class="sub_link">
                <p>公司沿革</p>
            </router-link>
          </div>
          <div class="footer_nav_item">
              <h6 class="sub_title txt-bold">最新活動</h6>
              <li>
                <router-link
                  :to="{
                    name: 'Events',
                    query: { category: '', page: '1' },
                  }"
                  class="">
                    活動資訊
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'News',
                    query: { category: '', page: '1' },
                  }"
                  class="">
                    最新消息
                </router-link>
              </li>
          </div>
          <div class="footer_nav_item">
            <h6 class="sub_title txt-bold">機場櫃台</h6>
            <router-link
              to="/Airport/1"
              class="sub_link">
                <p>桃園國際機場</p>
            </router-link>
            <router-link
              to="/Airport/2"
              class="sub_link">
                <p>台北松山機場</p>
            </router-link>
            <router-link
              to="/Airport/3"
              class="sub_link">
                <p>高雄小港機場</p>
            </router-link>
          </div>
          <div class="footer_nav_item">
            <h6 class="sub_title txt-bold">企業專區</h6>
            <router-link to="/CompanyArea" class="sub_link">
              <p>企業服務</p>
            </router-link>
            <a
              @click="companyLightboxStatusChange(true);"
              class="sub_link"
              style="cursor: pointer;">
                <p>洽詢商務合作</p>
            </a>
          </div>
          <div class="footer_nav_item">
            <h6 class="sub_title txt-bold">客服中心</h6>
            <router-link
              to="/Faq/1"
              class="sub_link">
                常見問題
            </router-link>
          </div>
          <div class="footer_nav_item">
            <h6 class="sub_title txt-bold">生活講堂</h6>
            <router-link
              :to="{
                name: 'Blog',
                query: { category: '', page: '1' },
              }"
              class="sub_link">
                <p>Wi-Ho! Blog</p>
            </router-link>
            <a
              @click="coopLightboxStatusChange(true);"
              class="sub_link"
              style="cursor: pointer;">
                <p>異業合作</p>
            </a>
          </div>
        </nav>
      </div>
      <div class="footer_more w1300">
        <small class="more_set">
          <router-link to="/Terms/Copyright">著作權聲明</router-link>&nbsp;|&nbsp;
          <router-link to="/Terms/Privacy">隱私權聲明</router-link>&nbsp;|&nbsp;
          <router-link to="/Terms/Rent">租賃條款</router-link>&nbsp;|&nbsp;
          <router-link to="/Terms/TurnBack">退貨說明</router-link>&nbsp;|&nbsp;
          <a href="https://www.104.com.tw/company/1a2x6bint0?jobsource=2018indexpoc">人才招募</a>&nbsp;|
          <a href="https://line.me/R/ti/p/@wiho" target="_blank">
            <img src="@/assets/img/icon/navbar_line.svg" alt="">
          </a>
          <a href="https://www.facebook.com/wihotw"  target="_blank">
            <img src="@/assets/img/icon/navbar_fb.svg" alt="">
          </a>
        </small>
      </div>
    </footer>

    <div id="copyright">
      <div class="inner w1300 txt-white">
        <small>
          <img src="@/assets/img/index/icon_phone.svg" alt="">
          02-2545-7777
          <img src="@/assets/img/index/icon_mail.svg" alt="" style="margin-left:10px;">
          cs_taiwan@tswiho.com
          特樂通股份有限公司
          統一編號：54167982
        </small>
        <small>
          Copyright © Telecom Square Taiwan, Inc. All right reserved.
        </small>
      </div>
    </div>
  </div>

</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'Footer',
  data() {
    return {
    };
  },
  computed: {
    ...mapState([
      'lightboxStatus',
    ]),
  },
  methods: {
    ...mapMutations([
      'coopLightboxStatusChange',
      'companyLightboxStatusChange',
    ]),
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
